import {
  Flex,
  Heading,
  Text,
  Card,
  Button,
  Image,
  TextField,
  Icon,
  Badge,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import { DataStore, Storage } from "aws-amplify";
import { Space, Project, PromoCode, Proposal } from "../models";
import axios from "axios";
import Swal from "sweetalert2";
import awsmobile from "../aws-exports";
import {proposalTemplateBackup} from "./HtmlEditor/proposalTemplateBackup";

const EmailEstimateModal = (props) => {
  const {
    colorMode,
    projectName,
    address,
    selectedMarkets,
    discipline,
    changeInSpace,
    constructionType,
    projectId,
    validateEmail,
    addCommaToPrice,
    projectPriceSheets,
    userDetails,
    projectSpaces,
    getProposalData,
    savePdfToS3,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    decodePDF,
  } = useContext(PzPrimeContext);

  const { route } = useAuthenticator((context) => [context.route]);
  const [spaces, setSpaces] = useState([]);
  const [additionalEmail, setAdditionalEmail] = useState("");

  useEffect(() => {
    getSpaces();
  }, [changeInSpace]);

  const useContextTradeConversion = {
    mechanical: "mech",
    electrical: "elec",
    plumbing: "plum",
  };

  function properNaming(field) {
    if (field) {
      const putSpace = field.split("_").join(" ");
      let name = "";
      for (const word of putSpace.split(" ")) {
        name +=
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
      }
      return name;
    } else return "";
  }

  async function getSpaces() {
    DataStore.observeQuery(Space, (space) =>
      space.and((space) => [
        space.projectSpacesId.eq(projectId),
        space.className.ne("Project Cost Flags"),
      ])
    ).subscribe(({ items }) => {
      setSpaces(items);
    });
  }

  let uniqueName = [];

  function totalSF(spaceType) {
    let totalSpace = 0;
    if (projectSpaces) {
      for (let space in projectSpaces) {
        if (projectSpaces[space]["className"] === spaceType) {
          for (let SF of projectSpaces[space]["areas"]) {
            totalSpace += parseInt(SF.area);
          }
        }
      }
    }
    return totalSpace;
  }

  async function sendProposalData() {
    Swal.fire({
      imageUrl: require("../Assets/loader1.gif"),
      imageAlt: "loader",
      imageHeight: "150px",
      imageWidth: "150px",
      timerProgressBar: true,
      html: "<b style='font-size:25px'>Drafting Proposal...</b>",
      didOpen: () => {
        const timer = Swal.getPopup().querySelector("b");
        setTimeout(() => {
          timer.textContent = `Sending email...`;
        }, 5000);
      },
      showConfirmButton: false,
    });
    const project = await DataStore.query(Project, projectId);
    let proposalData = await getProposalData(project);
    console.log(proposalData);
    if (validateEmail(additionalEmail)) {
      if (proposalData.ccAddresses.hasOwnProperty("value"))
        proposalData.ccAddresses.value[0] = additionalEmail;
      else {
        proposalData.ccAddresses["value"] = [additionalEmail];
      }
    } else {
      if (proposalData.ccAddresses.hasOwnProperty("value"))
        delete proposalData.ccAddresses["value"];
    }
    const sendInstantQuoteApi = process.env.REACT_APP_SEND_PROPOSAL_ENDPOINT;
    const primaryEmailAddress = process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;
    console.log(typeof primaryEmailAddress);
    let message;
    let response;
    await axios
      .post(sendInstantQuoteApi, {
        // project: project,
        bucketName: awsmobile.aws_user_files_s3_bucket,
        isRealQuote: true,
        primaryEmailAddress: primaryEmailAddress,
        proposalData: proposalData,
        additionalCCEmail: additionalEmail,
        isCustomProposal: false,
      })
      .then(async (res) => {
        response = res;
        if (res.data.emailResponse.messageId) {
          let pdfFile = decodePDF(res.data.proposalPdfB64String);
          const pdfKey = await savePdfToS3(
            pdfFile,
            project.name
          );
          if (
            project.priceSheet.promoDiscounts &&
            project.priceSheet.promoDiscounts.length > 0
          )
            await incrementPromoCodeUses(project.priceSheet.promoDiscounts[0].code);
          if (pdfKey.msg === "success") {
            let [result, proposal] = await saveProposal(project, proposalData, pdfKey.key);
            if(result)
              await updateProjectRecord(project, proposal, proposalData);
            message = "success";
            Swal.close();
            props.onClose();
            Swal.fire({
              title: "Proposal sent!",
              showConfirmButton: true,
              confirmButtonText: `Close`,
              confirmButtonColor: "#40aabf",
              // showDenyButton: true,
              // denyButtonText: "Home",
              // denyButtonColor: "#40aabf",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.close();
              } else if (result.isDenied) {
                window.location.reload();
              }
            });
          }
        else message = "error";
        } else message = "error";
      })
      .catch((e) => {
        response = e;
        message = "catch-error";
        console.log("sendInstantQuote catch", e);
      });

    return [message, response];
  }

  const modalCss = `

  .projectSummaryScroll{
    overflow-y:scroll;
  }
  .scrollBar::-webkit-scrollbar {
    width: 25px;               /* width of the entire scrollbar */
  }

  .scrollBar::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    // background-color: #F5F5F5;      /* color of the tracking area */
  }

  .scrollBar::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    background-clip: content-box;
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 8px solid transparent;  /* creates padding around scroll thumb */
  }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  `;

  return (
    <>
      <style>{modalCss}</style>
      <Flex
        direction={"row"}
        maxHeight={{
          base: "400px",
          small: "400px",
          medium: "400px",
          large: "400px",
          xl: "400px",
          xxl: "500px",
          xxxl: "700px",
        }}
        maxWidth={{
          base: "500px",
          small: "500px",
          medium: "700px",
          large: "900px",
          xl: "900px",
          xxl: "1100px",
          xxxl: "1100px",
        }}
      >
        <Flex
          direction={"column"}
          minWidth={"450px"}
          backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
          borderRadius={"15px"}
          padding={"10px 15px"}
          maxHeight={"780px"}
          className="projectSummaryScroll scrollBar"
        >
          <Heading padding={"10px"} level={4} textAlign={"center"}>
            Project Estimate
          </Heading>

          <Card
            display={"flex"}
            gap="10px"
            direction={"column"}
            borderRadius="10px"
            border="1px solid #AEB3B7"
            backgroundColor={colorMode === "dark" && "#2e3436"}
            width={"100%"}
          >
            <Text
              textAlign={"center"}
              marginBottom={"5px"}
              fontWeight={"500"}
              fontSize={"20px"}
            >
              {projectName}
            </Text>

            <Text>
              <Icon margin={"0px 5px 6px 0px"}>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="#c5c5c5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11.3254C9 12.121 9.31607 12.8841 9.87868 13.4467C10.4413 14.0093 11.2044 14.3254 12 14.3254C12.7956 14.3254 13.5587 14.0093 14.1213 13.4467C14.6839 12.8841 15 12.121 15 11.3254C15 10.5297 14.6839 9.76668 14.1213 9.20407C13.5587 8.64146 12.7956 8.32539 12 8.32539C11.2044 8.32539 10.4413 8.64146 9.87868 9.20407C9.31607 9.76668 9 10.5297 9 11.3254Z"
                    stroke="#354052"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.657 16.9824L13.414 21.2254C13.039 21.6 12.5306 21.8105 12.0005 21.8105C11.4704 21.8105 10.962 21.6 10.587 21.2254L6.343 16.9824C5.22422 15.8636 4.46234 14.4381 4.15369 12.8863C3.84504 11.3344 4.00349 9.72591 4.60901 8.26412C5.21452 6.80234 6.2399 5.55293 7.55548 4.6739C8.87107 3.79486 10.4178 3.32568 12 3.32568C13.5822 3.32568 15.1289 3.79486 16.4445 4.6739C17.7601 5.55293 18.7855 6.80234 19.391 8.26412C19.9965 9.72591 20.155 11.3344 19.8463 12.8863C19.5377 14.4381 18.7758 15.8636 17.657 16.9824Z"
                    stroke="#354052"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Icon>
              {address}
            </Text>

            <Flex wrap={"wrap"} gap={"8px"}>
              {Object.keys(discipline).map((department, index) => {
                if (discipline[department] === 1)
                  return <Badge key={index}>{properNaming(department)}</Badge>;
              })}
            </Flex>

            <Text>Construction Type:</Text>
            <Badge width={"fit-content"}>{constructionType}</Badge>

            <Flex
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              padding={"10px 0"}
              direction={"column"}
              gap={"10px"}
            >
              <Heading level={4}>Total Costs</Heading>
              <Text fontWeight={500} fontSize={"22px"}>
                ${" "}
                {projectPriceSheets
                  ? projectPriceSheets["total"]
                    ? addCommaToPrice(projectPriceSheets["total"])
                    : "0"
                  : "0"}
              </Text>
              <Flex
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                margin={"5px 0"}
              >
                <Flex
                  width={"100%"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                  gap={"20px"}
                  direction={"column"}
                  paddingLeft={"30px"}
                >
                  {Object.keys(discipline).map((department, index) => {
                    if (discipline[department] === 1)
                      return (
                        <Text key={index} fontSize={"18px"}>
                          {department.charAt(0).toUpperCase() +
                            department.slice(1)}
                        </Text>
                      );
                  })}
                </Flex>
                <Flex
                  width={"100%"}
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  gap={"20px"}
                  direction={"column"}
                  paddingRight={"40px"}
                >
                  {Object.keys(discipline).map((department, index) => {
                    if (discipline[department] === 1)
                      return (
                        <Text
                          key={index}
                          fontWeight={500}
                          fontSize={"17px"}
                          className="Mechanical"
                          textAlign={"right"}
                        >
                          {projectPriceSheets
                            ? addCommaToPrice(
                                projectPriceSheets[
                                  useContextTradeConversion[department] +
                                    "Total"
                                ]
                              )
                            : "0"}{" "}
                          $
                        </Text>
                      );
                  })}
                </Flex>
              </Flex>
            </Flex>
          </Card>
          {selectedMarkets.length !== 0 && (
            <Card
              display={"flex"}
              gap="8px"
              direction={"column"}
              borderRadius="10px"
              border="1px solid #AEB3B7"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              justifyContent="center"
              width={"100%"}
            >
              <Text
                textAlign={"center"}
                marginBottom={"5px"}
                fontWeight={"500"}
                fontSize={"20px"}
              >
                Selected Markets
              </Text>

              <Flex direction={"column"} gap={"10px"} width={"100%"}>
                {selectedMarkets.map((item, index) => (
                  <Badge
                    display={"flex"}
                    justifyContent="center"
                    key={index}
                    textAlign={"center"}
                  >
                    {properNaming(item)}
                  </Badge>
                ))}
              </Flex>
            </Card>
          )}
          {projectSpaces && Object.keys(projectSpaces).length !== 0 && (
            <Card
              display={"flex"}
              gap="8px"
              direction={"column"}
              borderRadius="5px"
              border="1px solid #AEB3B7"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              justifyContent="center"
              width={"100%"}
            >
              <Text
                textAlign={"center"}
                marginBottom={"5px"}
                fontWeight={"500"}
                fontSize={"20px"}
              >
                Selected Spaces
              </Text>
              <Flex direction={"column"} gap={"10px"} width={"100%"}>
                {Object.keys(projectSpaces).map((space, index) => {
                  if (!uniqueName.includes(projectSpaces[space]["className"])) {
                    let spaceFilter = Object.keys(projectSpaces).filter(
                      (spaceObj) =>
                        projectSpaces[space]["className"] ===
                        projectSpaces[spaceObj]["className"]
                    );
                    uniqueName.push(projectSpaces[space]["className"]);
                    return (
                      <Flex
                        justifyContent="space-between"
                        key={index}
                        padding={"3px 10px"}
                        borderRadius={"20px"}
                        backgroundColor={
                          colorMode === "dark" ? "#c5c5c5" : "#EFF0F0"
                        }
                      >
                        <Badge>
                          {projectSpaces[space]["className"]} (
                          {spaceFilter.length})
                        </Badge>
                        <Badge backgroundColor={"#40AABF"} color={"#fff"}>
                          {addCommaToPrice(
                            totalSF(projectSpaces[space]["className"])
                          )}{" "}
                          SF
                        </Badge>
                      </Flex>
                    );
                  }
                })}
              </Flex>
            </Card>
          )}
        </Flex>
        <Flex direction={"column"} className="projectSummaryScroll scrollBar">
          <Flex
            alignItems={"center"}
            borderRadius="10px 10px 0px 0px"
            width="100%"
          >
            <Image
              alt="PermitZip logo"
              src={require("../Assets/permitZIP.png")}
              height="85px"
              width={"85px"}
              padding={"2px"}
              backgroundColor={"white"}
              borderRadius={"50%"}
              margin={"0px 5px"}
            />
            <Heading level="4"> Your Project Estimate Summary</Heading>
          </Flex>
          <Card
            margin="10px"
            borderRadius="15px"
            width="initial"
            maxWidth={"500px"}
            minWidth={"380px"}
            height="auto"
            border="1px solid gray"
          >
            <Flex direction={"column"}>
              {route === "authenticated" ? (
                <Flex direction={"column"} gap={"20px"}>
                  <Text>
                    You are logged in as
                    <Text fontWeight={"bold"}>{userDetails.emailId}</Text>
                  </Text>
                </Flex>
              ) : (
                <Flex direction={"column"} gap={"20px"}>
                  <Text>
                    You have provided the guest email address
                    <Text fontWeight={"bold"}>{userDetails.emailId}</Text>
                  </Text>
                </Flex>
              )}
              <Text maxWidth={"500px"}>
                If you would like to CC this proposal to an additional email
                address, please enter below:
              </Text>
              <TextField
                variation="quiet"
                size="default"
                placeholder="Additional email address..."
                errorMessage="enter a valid email address"
                hasError={
                  additionalEmail.length > 0 && !validateEmail(additionalEmail)
                }
                onChange={(e) => setAdditionalEmail(e.target.value)}
                value={additionalEmail}
              />
              <Button
                margin={"15px 0"}
                width={"100%"}
                maxWidth={"600px"}
                fontSize="18px"
                color={"#fff"}
                fontWeight="600"
                backgroundColor={"brand.primary.80"}
                onClick={() => sendProposalData()}
              >
                Send my Project Estimate
              </Button>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};

export default EmailEstimateModal;
